<template>
  <div class="timeline-item">
    <div class="timeline-badge">
      <div :class="`bg-${setStatus(status).class}`"></div>
    </div>
    <div class="timeline-label">
      <span class="text-primary font-weight-bold">
        {{ date }}
      </span>
    </div>
    <div class="timeline-content">
      <h5 class="d-flex align-items--center text-dark-50 mb-5">
        {{ title }}
      </h5>
      <div>
        {{ description }}
      </div>
      <!-- <b-btn v-if="status === 3" variant="primary" class="mt-5">
        View Application
      </b-btn>
      <b-btn v-else variant="warning" class="mt-5">
        Edit Application
      </b-btn> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'KTTimelineAltItem',
  props: {
    date: String,
    title: String,
    description: String,
    status: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    setStatus(status) {
      const statuses = {
        0: {
          class: 'secondary',
          text: 'default',
        },
        1: {
          class: 'primary',
          text: 'important',
        },
        2: {
          class: 'warning',
          text: 'pending',
        },
        3: {
          class: 'success',
          text: 'completed',
        },
      };

      return statuses[status];
    },
  },
};
</script>
