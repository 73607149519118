<template>
  <div>
    <b-container v-if="application.id" class="max-w-900px px-0">
      <b-card body-class="d-flex p-0" class="card-custom card-stretch gutter-b">
        <div
          class="flex-grow-1 p-12 card-rounded bgi-no-repeat d-flex flex-column justify-content-center align-items-start"
          :style="{
            backgroundPosition: 'right bottom',
            backgroundSize: 'auto 100%',
            backgroundImage: 'url(media/svg/humans/custom-8.svg)',
          }"
        >
          <h4 class="font-weight-bolder">{{ currentState.title }}</h4>
          <span class="text-primary font-weight-bold"> {{ currentState.date | moment('LL') }} </span>
          <p class="text-dark-50 my-5 font-size-xl font-weight-bold current-state-text">
            {{ currentState.text }}
            <template v-if="isReason(application.status)">
              <br />
              {{ application.reason }}
            </template>
            <template
              v-if="application.status == 'Interview Scheduled' || application.status == 'Interview Rescheduled'"
            >
              <br />
              <strong>
                Interview is scheduled on
                {{ get(application, 'last_interview_schedule.data.attributes.date') }}
                {{ get(application, 'last_interview_schedule.data.attributes.time') | moment('hh:mm A') }}
              </strong>
            </template>
          </p>

          <div class="d-flex">
            <b-btn
              :to="{ name: 'applications-single-details' }"
              variant="primary"
              class="mr-2 font-weight-bold py-2 px-6"
            >
              View Application
            </b-btn>

            <b-btn
              v-if="showBtn(application)"
              :to="{ name: 'applications-create' }"
              variant="warning"
              class="font-weight-bold py-2 px-6"
            >
              {{ isReApply ? 'Reapply' : 'Edit' }} Application
            </b-btn>
          </div>
        </div>
      </b-card>

      <b-card class="card-custom" footer-class="text-center">
        <!-- <template v-slot:footer>
          <b-btn
              variant="primary"
              class="px-10"
          >
            Load More
          </b-btn>
        </template> -->
        <KTTimelineAltList :items="applications" />
      </b-card>
    </b-container>
    <template v-else-if="!loading">
      <b-card class="card-custom" footer-class="text-center">
        <h4 class="text-center">Something went wrong</h4>
      </b-card>
    </template>
  </div>
</template>

<script>
import applicationActions from '@/mixins/application-actions';
import ApiService from '@/helpers/api.service';
import KTTimelineAltList from '@/components/generic/TimelineAltList';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'applications-index',
  components: {
    KTTimelineAltList,
  },
  mixins: [applicationActions],
  data() {
    return {
      loading: false,
      applications: [
        {
          title: 'Application Created',
          text: 'Congratulations! Your application has been created.',
          // date: 'October 15, 2019',
          status: 3,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      application: 'applications/item',
      user: 'auth/user',
    }),
    currentState() {
      return this.applications[0];
    },
    applicationId() {
      return this.get(this.user, 'application.data.id', null);
    },
    isReApply() {
      return this.application.days_to_left != null && this.application.days_to_left <= 0 && this.application.rejected;
    },
  },
  async mounted() {
    this.loading = true;

    await this.$await(this.getApplication(this.applicationId));

    this.loading = false;
    if (this.application.step_status < 6) this.$router.push({ name: 'applications-create' });
    this.applications[0].date = this.application.created_at;
    ApiService.query('/application_histories', { per: Math.pow(10, 12) }).then((res) => {
      let { data } = res.data;
      data = data.map((d) => {
        return {
          id: d.id,
          ...d.attributes,
        };
      });
      this.applications = [...data, ...this.applications];
    });
  },
  methods: {
    ...mapActions({
      getApplication: 'applications/show',
    }),
    showBtn(application) {
      return !application.status ||
        application.status == 'In Progress' ||
        application.status == 'Application Incomplete' ||
        (Number(application.days_to_left) <= 0 && application.rejected)
        ? true
        : false;
    },
  },
};
</script>
<style lang="scss">
.current-state-text {
  position: relative;
  border-radius: 0.42rem;
  padding: 1rem 1.5rem;
  background-color: #f6fbff;
}
</style>
