<template>
  <div class="timeline timeline-justified timeline-4">
    <div class="timeline-bar"></div>
    <div class="timeline-items">
      <KTTimelineAltItem v-for="(item, index) in items" :key="index" :title="item.title" :date="item.date | moment('LL')" :status="item.status" :description="item.text" />
    </div>
  </div>
</template>

<script>
import KTTimelineAltItem from '@/components/generic/TimelineAltItem';

export default {
  name: 'KTTimelineAltList',
  components: {
    KTTimelineAltItem,
  },
  props: {
    items: Array,
  },
};
</script>
