export default {
  data() {
    return {
      autoRejectText: 'Reject the application',
      autoRejectStatusText: 'Аutomatic Rejection',
    };
  },
  computed: {
    statuses() {
      let data = [
        { text: 'Invite Pending', color: 'warning' },
        { text: 'In Progress', color: 'primary' },
        { text: 'Coordinator Review', color: 'primary' },
        { text: 'Application Incomplete', color: 'danger' },
        { text: 'Board Review', color: 'primary' },
        { text: 'Interview Scheduled', color: 'warning' },
        // { text: 'Interview Rescheduled', color: 'warning' },
        { text: 'Board Rejected', color: 'danger' },
        { text: 'Board Accepted', color: 'success' },
        { text: 'Application Approved', color: 'success' },
        { text: 'Application Rejected', color: 'danger' },
        // { text: '60 Day Rejection', color: 'danger' },
      ];

      // const w = [
      //   'Invite Pending',
      //   'In Progress',
      //   'Coordinator Review',
      //       'Coordinator Rejected',
      //   'Board Review',
      //   'Interview Scheduled',
      //   'Board Rejected',
      //       'Board Approved',
      //   'Application Approved',
      //       'Application Rejected - Incomplete',
      //       'Application Rejected (automatic)',
      //       'App Coordinator Confirms Rejection',
      //       'App Coordinator Confirms Approval',
      //      'Coordinator Approves',
      // ];

      data = data.map((d) => {
        return {
          text: d.text,
          value: d.text,
          color: d.color,
        };
      });
      return data;
    },
  },
  methods: {
    hasViewAction(item, hasAttr = true) {
      const data = hasAttr ? item.attributes : item;

      return data.step_status >= 6 && data.status && data.status != 'Invite Pending' && data.status != 'In Progress';
    },
    isReason(status) {
      return status == 'Board Rejected' || status == 'Application Rejected' || status == 'Application Incomplete';
    },
    isInterview(status) {
      return status == 'Interview Scheduled' || status == 'Interview Rescheduled';
    },
    getActions(item, hasAttr = true) {
      const sActions = {
        'Board Review': { text: 'Board Review', icon: 'lar la-file-alt' },
        'Board Accepted': { text: 'Board Accepted', icon: 'la la-check' },
        'Board Rejected': { text: 'Board Rejected', icon: 'la la-times' },
        'Application Incomplete': { text: 'Application Incomplete', icon: 'la la-times' },
        'Schedule Interview': { text: 'Schedule Interview', value: 'Interview Scheduled', icon: 'la la-refresh' },
        'Reschedule Interview': { text: 'Reschedule Interview', value: 'Interview Rescheduled', icon: 'la la-refresh' },
        'Application Rejected': { text: 'Application Rejected', icon: 'la la-times' },
        'Application Approved': { text: 'Application Approved', icon: 'la la-check' },
      };
      const actions = [];
      const data = hasAttr ? item.attributes : item;
      const status = data.status;
      const cooperative = { id: data?.cooperative?.data.id, ...data?.cooperative?.data?.attributes };
      const isCoordinatorInterviewer = cooperative.who_schedules_interview == 'App Coordinator';
      const isBoardInterviewer = cooperative.who_schedules_interview == 'Board President';

      if (
        (data.days_to_left != null && data.days_to_left <= 0 && data.rejected) ||
        (data.days_to_left <= 0 && this.isBoardmemberPresident)
      ) {
        return [];
      }

      if (data.days_to_left != null && data.days_to_left <= 0) {
        return [{ text: 'Reject the application', icon: 'la la-times', autoReject: true }];
      }

      if (this.isCoordinator) {
        if (status == 'Coordinator Review') {
          actions.push(sActions['Board Review']);
          actions.push(sActions['Application Incomplete']);
        }

        if (status == 'Board Review' && isCoordinatorInterviewer) actions.push(sActions['Schedule Interview']);

        if (status == 'Interview Scheduled' && isCoordinatorInterviewer) actions.push(sActions['Reschedule Interview']);

        if (status == 'Board Rejected') actions.push(sActions['Application Rejected']);

        if (status == 'Board Accepted') actions.push(sActions['Application Approved']);
      } else if (this.isBoardmemberPresident) {
        if (status == 'Board Review' && isBoardInterviewer) {
          actions.push(sActions['Schedule Interview']);
        }
        if (status == 'Board Review') {
          actions.push(sActions['Board Rejected']);
        }
        if (status == 'Interview Scheduled') {
          actions.push(sActions['Board Accepted']);
          actions.push(sActions['Board Rejected']);
          if (isBoardInterviewer) actions.push(sActions['Reschedule Interview']);
        }
      }
      return actions;
    },
  },
};
